.exam-info-page {
  height: 100vh;
  background-image: url("../assets/newBc.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    box-sizing: border-box;
    width: 90%;
    max-width: 400px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    max-height: 80vh;
    overflow-y: auto;

    // 美化滚动条
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      transition: background 0.3s ease;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .error-message {
      padding-top: 20px;
      display: grid;
      place-items: center;
      color: #ff4d4f;
      font-size: 16px;
      font-weight: 500;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 30px;
      color: #333;
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    }

    .input-group {
      margin-bottom: 25px;

      .input-label {
        font-size: 16px;
        color: #333;
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
      }

      .input-field {
        width: 100%;
        border: none;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 12px;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;

        &:focus {
          outline: none;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
        }
      }
    }

    .query-button,
    .back-button,
    .floor-button {
      display: block;
      width: 100%;
      background-color: #1aad19;
      color: #fff;
      border: none;
      border-radius: 8px;
      padding: 12px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: 25px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #148f14;
        transform: translateY(-2px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }

    .floor-button {
      background-color: #1f9cf0;

      &:hover {
        background-color: #1a89d9;
      }
    }

    .exam-info {
      margin-top: 30px;

      .info-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 18px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.12);
        }

        .info-label {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          margin-right: 10px;
        }

        .info-value {
          font-size: 16px;
          color: #555;
        }
      }
    }

    .img-container {
      border-radius: 12px;
      overflow: hidden;
      margin-top: 25px;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);

      img {
        width: 100%;
        height: auto;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
}
